import {
  Chart as ChartJS,
  registerables
} from 'chart.js';

import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(...registerables);
interface VerticalBarChartProps {
  labels: string[];
  dataSets: {data: number[], backgroundColor: string}[]
  displayDataLabels: boolean;
  chartTitle?: string;
  customAspectRatio?: number;
  customBarPercentage?: number;
  customCategoryPercentage?: number;
}

function VerticalBarChart({ 
  labels, 
  dataSets, 
  displayDataLabels, 
  chartTitle, 
  customAspectRatio, 
  customBarPercentage, 
  customCategoryPercentage 
}: VerticalBarChartProps) {

  const _datasets = dataSets.map(dataSet => {
    return {
      data: dataSet.data,
      backgroundColor: dataSet.backgroundColor,
      borderRadius: 5,
      borderSkipped: false,
      barPercentage: customBarPercentage || 0.95,
      categoryPercentage: customCategoryPercentage || 1.0
    };
  });

  const data = {
    labels: labels,
    datasets: [..._datasets]
  };

  return (
    <Chart 
      plugins={[ChartDataLabels]}
      type="bar"
      options={{
        events: [],
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: customAspectRatio || 3/0.9,
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: true,
            text: chartTitle || '',
            padding: {
              bottom: 20
            }
          },
          datalabels: {
            display: displayDataLabels,
            align: 'end',
            anchor: 'end',
          }
        },
        scales: {
          x: {
            grid: {
              display: false
            },
          },
          y: {
            display: false,
            grid: {
              display: false
            },
          }
        }
      }}
      data={data}
    />
  );
}

export default VerticalBarChart;
