import SelectionStep from './SelectionStep';
import { SelectionStepProps } from './SelectionStep';
import { SelectionStepExtendedConfig } from './selectionStepsConfigs';
import useFetchData from '../../hooks/useFetchData';
import Loader from '../../reusable/Loader';

interface InitialSelectionStepProps extends Omit<SelectionStepProps, 'data'> {
  fetchData: SelectionStepExtendedConfig['fetchData']
  errorMessage: string;
  updateCachedData: (cacheData: string[]) => void;
}
const InitializeSelectionStep = ({ selectionStepKey, selectionStepLabel, errorMessage, fetchData, handleChangeStep, selectedData, updateCachedData, handleSelection }: InitialSelectionStepProps) => {
  const { isPending, error, response } = useFetchData({
    fetchData: fetchData,
    errorMessage: errorMessage,
  });

  if (isPending) return <Loader />;

  if (error || !response) return <p>{errorMessage}</p>;

  updateCachedData(response.data);

  return <SelectionStep
    data={response.data}
    selectedData={selectedData}
    selectionStepKey={selectionStepKey}
    selectionStepLabel={selectionStepLabel}
    handleChangeStep={handleChangeStep}
    handleSelection={handleSelection}
  />;
};

export default InitializeSelectionStep;