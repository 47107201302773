import { Tab, Tabs } from '@mui/material';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface CustomTabsProps {
    selectedTab: number;
    handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
    items: Array<{
        label: string;
        disabled: boolean;
    }>;
}
  
function CustomTabs({selectedTab, handleTabChange, items}: CustomTabsProps) {
  return (
    <Tabs 
      value={selectedTab} 
      onChange={handleTabChange} 
      aria-label="details tabs"
      centered
    >
      {items.map((item, index) => (
        <Tab key={index} label={item.label} {...a11yProps(index)} disabled={item.disabled}/>
      ))}
    </Tabs>
  );
}

export default CustomTabs;