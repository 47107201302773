import React, { useState } from 'react';
import SideDrawer from './SideDrawer';
import CardContainer from './CardContainer';
import OpenModalIconButton from '../reusable/OpenModalIconButton';
import OpenModalIcon from '../assets/open-modal.svg';

import './DataQcContentContainer.css';

interface DataQcContentContainerProps {
  drawerTitle: string;
  drawerContent: React.ReactNode
  cardTitle: string;
  cardContent: React.ReactNode;
}

const DataQcContentContainer = ({ cardContent, drawerContent, drawerTitle, cardTitle }: DataQcContentContainerProps) => {

  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState<boolean>(true);

  const toggleSideDrawer = () => {
    setIsSideDrawerOpen(prev => !prev);
  };

  return (
    <div className="data-qc-card-side-drawer-container">
      <CardContainer 
        title={cardTitle} 
        classes={['data-qc-card-container']} 
        button={<OpenModalIconButton handleOpen={toggleSideDrawer} buttonLabel="View more" icon={OpenModalIcon}/>}>
        {cardContent}
      </CardContainer>
      <SideDrawer title={`${drawerTitle} detail`} classes={['data-qc-drawer-container']} handleClose={toggleSideDrawer} isOpen={isSideDrawerOpen}>
        {drawerContent}
      </SideDrawer>
    </div>
  );
};

export default DataQcContentContainer;