import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// import { SelectChangeEvent } from '@mui/material';
// import useSchools from '../hooks/useSchools';
// import Loader from '../reusable/Loader';
import PageHeader from '../reusable/PageHeader';
import { NAVIGATION_LINKS_DATA } from '../../constants';

// import SelectDropdown from '../reusable/SelectDropdown';

const pageDescription = 'Review uploaded district data and make sure it\'s accurate.';

function DataQcLayout() {
  const [contextData,] = useState({ selectedSchool: '' });

  // const { schools: {isPending, error, data: schools }} = useSchools();

  // const handleChange = (event: SelectChangeEvent) => {
  //   setContextData({ selectedSchool: event.target.value });
  // };

  return (
    <>
      <PageHeader title={NAVIGATION_LINKS_DATA.DATA_QC.label} pageDescription={pageDescription}>
        {/* {isPending ? <Loader /> : null}
        {error ? <p>Missing schools data!</p> : null}
        {schools ?
          <SelectDropdown
            label="Students in EOA cohort at"
            selectedValue={contextData.selectedSchool}
            handleChange={handleChange}
            handleDelete={() => setContextData({ selectedSchool: '' })}
            items={schools.map(school => {
              return { label: school.name, value: school.id };
            })}
          />
          :
          null
        } */}
      </PageHeader>
      <div className="main-content">
        <Outlet context={contextData} />
      </div>
    </>
  );
}

export default DataQcLayout;