import GreenCheckMark from '../assets/green-checkmark.svg';
import './ColoredBoxWithCheckMark.css';

interface ColoredBoxWithCheckMarkProps {
  label: string,
  not_matched_count: number,
  percentage_matched: number
}

function ColoredBoxWithCheckMark({label, not_matched_count, percentage_matched}: ColoredBoxWithCheckMarkProps) {
  return (
    <div className="colored-box-content">
      <p className="colored-box-container-header">{label}</p>
      <div className="colored-box-container">
        {not_matched_count > 0 ?
          <p>{not_matched_count}</p>
          :
          <img alt="green checkmark" className="green-checkmark" src={GreenCheckMark}/>
        }
      </div>
      {not_matched_count > 0 ?
        <div className="colored-box-container-footer">Scores not matched</div> 
        :
        <div className="colored-box-container-footer">{`${percentage_matched}% Matched`} </div>
      }
    </div>
  );
}

export default ColoredBoxWithCheckMark;