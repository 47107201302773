import { CtePathwaysSummary } from '../../../interfaces/DataQcInterface';
import { getCtePathwaysSummary } from '../../../services/dataQcSummaryService';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import { withData } from '../../reusable/withData';

interface CteCoursesContentProps {
  data: CtePathwaysSummary;
}

function CteCoursesContent({data}: CteCoursesContentProps) {

  const { cte_catalogs_count, cte_eligible_courses_count, cte_pathways_count } = data;

  return (
    <ul data-testid="cte-courses-list">
      <li>{cte_catalogs_count} CTE catalogs</li>
      <li>{cte_eligible_courses_count} classes eligible for CTE pathways</li>
      <li>{cte_pathways_count} CTE pathways</li>
    </ul>
  );
}

const CteCoursesContentWithData = () => {
  
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const WrappedComponent = withData({
    WrappedComponent: CteCoursesContent,
    fetchData: (eoaId: string, access_token: string) => getCtePathwaysSummary(eoaId, access_token, selectedSchoolFromDataQc),
    errorMessage: 'Error occurred while fetching CTE pathways summary data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  return <WrappedComponent />;
};

export default CteCoursesContentWithData;