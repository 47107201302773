import SelectionStep from './SelectionStep';
import InitializeSelectionStep from './InitializeSelectionStep';
import { SELECTION_STEPS } from './selectionStepsConfigs';
import FileTypesValues from '../../../interfaces/FileType';
import selectionStepsMap, { SelectionStepExtendedConfig, TEST_TYPES } from './selectionStepsConfigs';
import useSchools from '../../hooks/useSchools';
import Loader from '../../reusable/Loader';

interface SelectionStepRendererProps {
  currentStep: typeof SELECTION_STEPS['SCHOOL'];
  handleChangeStep: () => void;
  selectedData: string[];
  data: string[] | null;
  fileType: FileTypesValues;
  updateCachedData: (data: string[]) => void;
  handleSelection: (stepKey: string, isSelected: boolean, value: string[]) => void;
}
function SelectionStepRenderer({ currentStep, handleChangeStep, selectedData, data, fileType, updateCachedData, handleSelection }: SelectionStepRendererProps) {
  const { schools: { isPending, error, data: schools } } = useSchools();

  if (currentStep.key === SELECTION_STEPS.SCHOOL.key) {
    if (isPending) return <Loader />;
    if (error || !schools) return <p>Missing schools data!</p>;

    return (
      <SelectionStep
        handleChangeStep={handleChangeStep}
        handleSelection={handleSelection}
        data={schools.map(school => school.name)}
        selectedData={selectedData}
        selectionStepKey={SELECTION_STEPS.SCHOOL.key}
        selectionStepLabel={SELECTION_STEPS.SCHOOL.label}
      />
    );
  }

  if (currentStep.key === SELECTION_STEPS.TEST_TYPES.key) {
    return (
      <SelectionStep
        handleChangeStep={handleChangeStep}
        handleSelection={handleSelection}
        data={TEST_TYPES}
        selectedData={selectedData}
        selectionStepKey={SELECTION_STEPS.TEST_TYPES.key}
        selectionStepLabel={SELECTION_STEPS.TEST_TYPES.label}
      />
    );
  }

  if (!data) {
    let stepConfig = selectionStepsMap[fileType] as SelectionStepExtendedConfig;

    return (
      <InitializeSelectionStep
        selectedData={selectedData}
        selectionStepKey={currentStep.key}
        selectionStepLabel={currentStep.label}
        fetchData={stepConfig.fetchData}
        errorMessage={stepConfig.errorMessage}
        handleChangeStep={handleChangeStep}
        handleSelection={handleSelection}
        updateCachedData={updateCachedData}
      />
    );
  }

  return (
    <SelectionStep
      handleChangeStep={handleChangeStep}
      handleSelection={handleSelection}
      data={data}
      selectedData={selectedData}
      selectionStepKey={currentStep.key}
      selectionStepLabel={currentStep.label}
    />
  );
}

export default SelectionStepRenderer;