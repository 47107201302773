import { useState } from 'react';
import { getTranscriptsSummary } from '../../../services/dataQcSummaryService';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import CustomPagination from '../../reusable/CustomPagination';
import { calculateItemsRange } from '../../reusable/CustomPagination';
import { DATA_QC_TRANSCRIPTS_ITEMS_PER_PAGE } from '../../../constants';
import { withData } from '../../reusable/withData';
import { TranscriptsSummary } from '../../../interfaces/DataQcInterface';

interface TranscriptsContentProps {
  data: TranscriptsSummary;
}

function TranscriptsContent({data}: TranscriptsContentProps) {
  const [currentPage, setCurrentPage] = useState(1);

  const totalItems = data.schools.length;
  const pageSize = DATA_QC_TRANSCRIPTS_ITEMS_PER_PAGE;
  const { startItem, endItem } = calculateItemsRange({ totalItems, pageSize, currentPage });

  const {total_num_schools_with_transcripts, total_num_transcripts, schools} = data;

  return (
    <div data-testid="transcripts-content">
      <p>We found transcripts for {total_num_schools_with_transcripts} of the {schools.length} schools in your district</p>
      <ul>
        <li>
          {`${total_num_transcripts} transcripts total`}
          <ul className="list-type-disc">
            {schools.slice(startItem - 1, endItem).map(({ id, name, transcript_count }) => (
              <li key={id}>{transcript_count} students at {name}</li>
            ))}
          </ul>
        </li>
      </ul>
      <CustomPagination
        currentPage={currentPage}
        totalItems={totalItems}
        pages={Math.ceil(totalItems / pageSize)}
        pageSize={pageSize}
        handlePageChange={setCurrentPage}
      />
    </div>
  );
}

const TranscriptsContentWithData = () => {
  
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const WrappedComponent = withData({
    WrappedComponent: TranscriptsContent,
    fetchData: (eoaId: string, access_token: string) => getTranscriptsSummary(eoaId, access_token, selectedSchoolFromDataQc),
    errorMessage: 'Error occurred while fetching transcripts summary data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  return <WrappedComponent />;
};

export default TranscriptsContentWithData;