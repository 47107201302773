import CustomButton from './CustomButton';

interface OpenModalIconButtonProps {
  handleOpen: () => void;
  buttonLabel: string;
  icon: string;
}

function OpenModalIconButton({handleOpen: handleOpenModal, buttonLabel, icon}: OpenModalIconButtonProps) {

  return (
    <CustomButton
      variant="text" 
      buttonText={buttonLabel}
      buttonAction={handleOpenModal} 
      icon={<img src={icon} alt="open modal / open sidebar"></img>}
      iconPosition="end"
      sx={{'& .MuiButton-endIcon': { marginLeft: 'var(--scaling-spacing-4)'}}}
    />
  );
}

export default OpenModalIconButton;