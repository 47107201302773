import { CourseCatalogsSummary } from '../../../interfaces/DataQcInterface';
import { getCourseCatalogsSummary } from '../../../services/dataQcSummaryService';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import { withData } from '../../reusable/withData';

interface CourseCatalogsContentProps {
  data: CourseCatalogsSummary;
}

function CourseCatalogsContent({data}: CourseCatalogsContentProps) {

  const {course_catalog_count, total_course_count, course_counts} = data;

  return (
    <ul data-testid="course-catalog-list">
      <li>{course_catalog_count} course catalogs found</li>
      <li>
        {total_course_count} courses found
        <ul className="list-type-disc">
          {Object.entries(course_counts).map(([key, value]) => (
            <li key={key}>{value} {key}</li>
          ))}
        </ul>
      </li>
    </ul>
  );
}

const CourseCatalogsContentWithData = () => {
  
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const WrappedComponent = withData({
    WrappedComponent: CourseCatalogsContent,
    fetchData: (eoaId: string, access_token: string) => getCourseCatalogsSummary(eoaId, access_token, selectedSchoolFromDataQc),
    errorMessage: 'Error occurred while fetching course catalog summary data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  return <WrappedComponent />;
};

export default CourseCatalogsContentWithData;