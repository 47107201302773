import VerticalBarChart from '../../reusable/VerticalBarChart';
import HorizontalBarChart from '../../reusable/HorizontalBarChart';
import CardContainer from '../CardContainer';
import { getTestScoresDetails } from '../../../services/dataQcDetailsService';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import './TestScoresDrawerContainer.css';
import { darkGreenBarColor } from '../../../theme/xq-custom-theme';
import { withData } from '../../reusable/withData';
import { TestScoresDetails } from '../../../interfaces/DataQcInterface';
import { AxiosResponse } from 'axios';

interface TestScoresDetailsProps {
  data: PromiseSettledResult<AxiosResponse<TestScoresDetails>>[];
}

const prepareChartData = (subjects: { [key: string]: number } | null) => {
  if (!subjects) return { chartLabels: null, chartData: null };

  const chartLabels = Object.keys(subjects);
  const chartData = Object.values(subjects);

  return { chartLabels, chartData };
};

const parseData = (data: Awaited<ReturnType<typeof getTestScoresDetails>>) => {

  const props = ['sat', 'act', 'ap', 'ib', 'apSubjects', 'ibSubjects'];

  const parsedDataObject = {
    sat: [] as number[] | null,
    act: [] as number[] | null,
    ap: [] as number[] | null,
    ib: [] as number[] | null,
    apSubjects: {} as { [key: string]: number } | null,
    ibSubjects: {} as { [key: string]: number } | null
  };

  data.forEach((dataResult, index) => {
    let property = props[index];

    switch (property) {
    case 'sat':
    case 'act':
    case 'ap':
    case 'ib':
      if (dataResult.status === 'fulfilled' && dataResult.value.data.scores) parsedDataObject[property] = [...dataResult.value.data.scores];
      else parsedDataObject[property] = null;
      break;
    case 'apSubjects':
    case 'ibSubjects': 
      if (dataResult.status === 'fulfilled' && dataResult.value.data.subjects) parsedDataObject[property] = { ...dataResult.value.data.subjects };
      else parsedDataObject[property] = null;
      break;
    }
  });
  
  const {sat, act, ap, ib, apSubjects, ibSubjects} = parsedDataObject;

  const {chartLabels: apSubjectChartLabels, chartData: apSubjectChartData} = prepareChartData(apSubjects);
  const {chartLabels: ibSubjectChartLabels, chartData: ibSubjectChartData} = prepareChartData(ibSubjects);

  return {sat, act, ap, ib, apSubjectChartLabels, apSubjectChartData, ibSubjectChartLabels, ibSubjectChartData};
};

function TestScoresDrawerContent({data}: TestScoresDetailsProps) {

  const { sat, act, ap, ib, apSubjectChartLabels, apSubjectChartData, ibSubjectChartLabels, ibSubjectChartData } = parseData(data);

  return (
    <>
      <CardContainer title="SAT superscore ditribution" centerTitle={true}> 
        { sat ?
          <VerticalBarChart labels={sat.map(value => value.toString())}  dataSets={[{data: sat, backgroundColor: darkGreenBarColor}]} displayDataLabels={false} />
          :
          <p>Error occured while fetching "SAT superscore ditribution" data!</p>
        }
      </CardContainer>

      <CardContainer title="ACT superscore ditribution" centerTitle={true}> 
        { act ?
          <VerticalBarChart labels={act.map(value => value.toString())}  dataSets={[{data: act, backgroundColor: darkGreenBarColor}]} displayDataLabels={false} />
          :
          <p>Error occured while fetching "ACT superscore ditribution" data!</p>
        }
      </CardContainer>

      <CardContainer title="AP score ditribution" centerTitle={true}>
        { ap ?
          <VerticalBarChart labels={ap.map(value => value.toString())}  dataSets={[{data: ap, backgroundColor: darkGreenBarColor}]} displayDataLabels={false} />
          :
          <p>Error occured while fetching "AP score ditribution" data!</p>
        } 
        <p className="details-section-header">Number of tests found by subject area</p>
        { apSubjectChartData && apSubjectChartLabels ?
          <HorizontalBarChart labels={apSubjectChartLabels} dataValues={apSubjectChartData} />
          :
          <p>Error occured while fetching "Number of tests found by subject area" data!</p>
        }
      </CardContainer>

      <CardContainer title="IB score ditribution" centerTitle={true}> 
        {ib ?
          <VerticalBarChart labels={ib.map(value => value.toString())}  dataSets={[{data: ib, backgroundColor: darkGreenBarColor}]} displayDataLabels={false} />
          :
          <p>Error occured while fetching "IB score ditribution" data!</p>
        }
        <p className="details-section-header">Average number of tests taken per subject area</p>
        { ibSubjectChartData && ibSubjectChartLabels ?
          <HorizontalBarChart labels={ibSubjectChartLabels} dataValues={ibSubjectChartData} />
          :
          <p>Error occured while fetching "Average number of tests taken per subject area" data!</p>
        }
      </CardContainer>
    </>
  );
}

const TestScoresDrawerContentWithData = () => {
  
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const WrappedComponent = withData({
    WrappedComponent: TestScoresDrawerContent,
    fetchData: (eoaId: string, access_token: string) => getTestScoresDetails(eoaId, access_token, selectedSchoolFromDataQc),
    errorMessage: 'Error occurred while fetching test scores details data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  return <WrappedComponent />;
};

export default TestScoresDrawerContentWithData;
