// import { Link, useNavigate } from 'react-router-dom';
// import CardContainer from './CardContainer';
// import RosterContentWithData from './RosterContent';
// import DemographicsContentWithData from './demographics/DemographicsContent';
// import TranscriptsContentWithData from './transcripts/TranscriptsContent';
// import CteCoursesContentWithData from './cte courses/CteCoursesContent';
// import MatchingContentWithData from './matching/MatchingContent';
// import TestScoresContentWithData from './test scores/TestScoresContent';
// import CourseCatalogsContentWithData from './course catalog/CourseCatalogsContent';
// import OpenModalIconButton from '../reusable/OpenModalIconButton';
// import useUserDetails from '../hooks/useUserDetails';
// import { USER_ROLES } from '../../constants';
// import OpenModalIcon from '../assets/open-modal.svg';
import ContentPlaceholderCard from './ContentPlaceholderCard';

import './DataQcSummary.css';

// const cards = [
//   {title: 'Demographics', component: <DemographicsContentWithData />, path: '', shouldRenderViewMore: false},
//   {title: 'Roster', component: <RosterContentWithData />, path: '', shouldRenderViewMore: false},
//   {title: 'Test scores', component: <TestScoresContentWithData />, path: '/data-qc/test-scores', shouldRenderViewMore: true},
//   {title: 'Matching', component: <MatchingContentWithData />, path: '/data-qc/matching', shouldRenderViewMore: true},
//   {title: 'CTE courses', component: <CteCoursesContentWithData />, path: '/data-qc/cte-courses', shouldRenderViewMore: true},
//   {title: 'Course catalogs', component: <CourseCatalogsContentWithData />, path: '/data-qc/course-catalogs', shouldRenderViewMore: true},
//   {title: 'Transcripts', component: <TranscriptsContentWithData />, path: '/data-qc/transcripts', shouldRenderViewMore: true},
// ];

function DataQcSummary() {
  // const navigate = useNavigate();
  // const { data: userDetails } = useUserDetails();

  // const handleOpen = (path: string) => {
  //   if (path) navigate(path);
  // };

  // const renderViewMoreButton = (shouldRenderViewMore: boolean, path: string) => {
  //   if (shouldRenderViewMore) {
  //     return (
  //       <OpenModalIconButton handleOpen={() => handleOpen(path)} buttonLabel="View more" icon={OpenModalIcon}/>
  //     );
  //   }
  // };

  return (
    <div className="data-qc-main-content">
      {/* { userDetails && userDetails.role !== USER_ROLES.XQ_USER ?
        <div className="data-qc-info-container">
          <h3 className="data-qc-info-title">Does this information look wrong?</h3>
          <p>
            XQ may be missing information about your district. 
            You can fix this by uploading more data or editing errors on
            the <Link className="link" to="/data-upload">Data Upload page.</Link> For more help contact your <Link className="link" to="/help">XQ account manager</Link>.
          </p>
        </div>
        :
        null
      } */}
      <ContentPlaceholderCard />
      {/* <div className="data-qc-cards-container">
        {cards.map(({title, component, path, shouldRenderViewMore}, index) => (
          <CardContainer key={index} title={title} button={renderViewMoreButton(shouldRenderViewMore, path)} classes={['data-qc-summary-card']}>
            {component}
          </CardContainer>
        ))}
      </div> */}
    </div>
  );
}

export default DataQcSummary;