import { useState, useEffect } from 'react';
import useUserDetails from './useUserDetails';
import { AxiosError } from 'axios';
import useErrorModal from './useErrorModal';

interface FetchDataOptions {
  dependencies?: unknown[];
}

interface UseFetchDataParams<TData> {
  fetchData: (eoaId: string, access_token: string) => TData;
  errorMessage: string;
  options?: FetchDataOptions;
}

interface FetchDataState<TData> {
  isPending: boolean;
  error: AxiosError | Error | null;
  response: Awaited<TData> | null;
}

function useFetchData<T>({ fetchData, errorMessage, options }: UseFetchDataParams<T>) {
  const { data: userDetails } = useUserDetails();

  const [{ isPending, error, response }, setData] = useState<FetchDataState<T>>({ isPending: true, error: null, response: null });

  const { handleOpen: handleOpenErrorModal } = useErrorModal();

  const dependencies = options?.dependencies || [];

  useEffect(() => {
    if (userDetails) {
      getData(userDetails.eoaId, userDetails.access_token);
    } else {
      setData({ isPending: false, response: null, error: new Error('Request cannot be send due to mising user details data!') });
    }
  }, [userDetails, ...dependencies]);

  const getData = async (eoaId: string, access_token: string) => {
    try {
      setData({ isPending: true, response: null, error: null });

      const response = await fetchData(eoaId, access_token);

      setData({ isPending: false, response, error: null });
    } catch (error) {
      setData({ isPending: false, response: null, error: error as AxiosError});
      handleOpenErrorModal(errorMessage, error as AxiosError);
    }
  };

  return { isPending, error, response };
}

export default useFetchData;