import {
  Chart as ChartJS,
  registerables
} from 'chart.js';

import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(...registerables);

interface HorizontalBarChartProps {
  labels: string[];
  dataValues: number[]
}

function HorizontalBarChart({ labels, dataValues }: HorizontalBarChartProps) {

  const data = {
    labels: labels,
    datasets: [{
      axis: 'y',
      label: 'My First Dataset',
      data: dataValues,
      backgroundColor: '#489F55',
      borderRadius: 5,
      borderSkipped: false,
      barThickness: 15
    }]
  };

  const maxValue = Math.max(...dataValues);

  return (
    <Chart
      plugins={[ChartDataLabels]}
      type="bar"
      options={{
        events: [],
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 2/0.9,
        plugins: {
          tooltip: {
            enabled: false
          },
          legend: {
            display: false
          },
          datalabels: {
            display: true,
            align: 5,
            anchor: 'end',
            color: '#000000',
            font: {
              size: 16
            }
          },
        },
        indexAxis: 'y',
        scales: {
          x: {
            grid: {
              display: false
            },
            ticks: {
              display: false
            },
            border: {
              display: false
            },
            beginAtZero: true,
            grace: maxValue * 0.2
          },
          y: {
            grid: {
              display: false
            },
            border: {
              display: false
            },
            ticks: {
              color: '#000000',
              font: {
                size: 16
              },
              crossAlign: 'far',
              padding: 20
            }
          }
        }
      }}
      data={data}
    />
  );
}

export default HorizontalBarChart;