import { Box, Pagination, Stack } from '@mui/material';
import PaginationData from '../../interfaces/PaginationInterface';
import './CustomPagination.css';
interface CustomPaginationProps {
  currentPage: number;
  totalItems: number;
  pages: number;
  handlePageChange: (newPage: number) => void;
  pageSize: number;
}

function CustomPagination({ currentPage, handlePageChange, pages, totalItems, pageSize }: CustomPaginationProps) {

  const { startItem, endItem } = calculateItemsRange({ totalItems, pageSize, currentPage });

  return (
    <div className="pagination-container">
      <div>
        Showing <span data-testid="paginated-start-end-item" className="bolded">{startItem}-{endItem}</span> of <span className="bolded">{totalItems}</span>
      </div>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Stack spacing={2}>
          <Pagination
            onChange={(e, newPage) => handlePageChange(newPage)}
            count={pages}
            page={currentPage}
            boundaryCount={2}
            siblingCount={0}
            showFirstButton={false}
            showLastButton={false}
          />
        </Stack>
      </Box>
    </div>
  );
}

const calculateItemsRange = ({ totalItems, pageSize, currentPage }: PaginationData) => {
  const startItem = (currentPage - 1) * pageSize + 1;
  const endItem = Math.min(currentPage * pageSize, totalItems);

  return { startItem, endItem };
};

export default CustomPagination;
export { calculateItemsRange };