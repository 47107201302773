import VerticalBarChart from '../../reusable/VerticalBarChart';
import HorizontalBarChart from '../../reusable/HorizontalBarChart';
import { getTranscriptsDetails } from '../../../services/dataQcDetailsService';
import CardContainer from '../CardContainer';
import { darkGreenBarColor } from '../../../theme/xq-custom-theme';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import { withData } from '../../reusable/withData';
import { AxiosResponse } from 'axios';
import { TranscriptsDetails } from '../../../interfaces/DataQcInterface';

interface TranscriptsSideDrawerContentProps {
  data: PromiseSettledResult<AxiosResponse<TranscriptsDetails>>[];
}

function TranscriptsSideDrawerContent({data}: TranscriptsSideDrawerContentProps) {
  
  const parseData = () => {
    let gpaDistribution = null;
    let subjectCredits = null;

    if (data[0].status === 'fulfilled') {
      let _data = data[0].value.data;
      gpaDistribution = {
        labels: _data.scores.map(score => score.toString()),
        data: _data.scores
      };
    }

    if (data[1].status === 'fulfilled') {
      let _data = data[1].value.data;

      subjectCredits = {
        labels: Object.keys(_data.subjects),
        data: Object.values(_data.subjects)
      };
    }

    return { gpaDistribution, subjectCredits };
  };

  const { gpaDistribution, subjectCredits } = parseData();

  return (
    <>
      <CardContainer title="Student GPA distribution" centerTitle={true}>
        {gpaDistribution ?
          <VerticalBarChart
            labels={gpaDistribution.labels}
            dataSets={[{data: gpaDistribution.data, backgroundColor: darkGreenBarColor}]}
            displayDataLabels={false}
          />
          :
          <p>Error occured while fetching "Student GPA distribution" data!</p>
        }
      </CardContainer>
      <CardContainer title="Average number of credits taken per subject area" centerTitle={true}>
        {subjectCredits ?
          <HorizontalBarChart dataValues={subjectCredits.data} labels={subjectCredits.labels} />
          :
          <p>Error occured while fetching "Average number of credits taken per subject area" data!</p>
        }
      </CardContainer>
    </>
  );
}

const TranscriptsSideDrawerContentWithData = () => {
  
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const WrappedComponent = withData({
    WrappedComponent: TranscriptsSideDrawerContent,
    fetchData: (eoaId: string, access_token: string) => getTranscriptsDetails(eoaId, access_token, selectedSchoolFromDataQc),
    errorMessage: 'Error occurred while fetching transcript details data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  return <WrappedComponent />;
};

export default TranscriptsSideDrawerContentWithData;