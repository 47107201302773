import { MatchingSummary } from '../../../interfaces/DataQcInterface';
import { getMatchingSummary } from '../../../services/dataQcSummaryService';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import { withData } from '../../reusable/withData';

interface MatchingContentProps {
  data: MatchingSummary;
}

function MatchingContent({data}: MatchingContentProps) {

  const {
    students_matched_percentage, 
    transcript_courses_matched_percentage, 
    test_scores_matched_to_students_percentage, 
    cte_courses_matched_percentage 
  } = data;

  return (
    <ul data-testid="matching-content">
      <li>{students_matched_percentage}% of students matched across demographics, transcripts, and test scores</li>
      <li>{transcript_courses_matched_percentage}% of transcript courses matched to course catalog courses</li>
      <li>{test_scores_matched_to_students_percentage}% of test scores matched to students</li>
      <li>{cte_courses_matched_percentage}% of CTE courses matched to course catalog courses</li>
    </ul>
  );
}

const MatchingContentWithData = () => {
  
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const WrappedComponent = withData({
    WrappedComponent: MatchingContent,
    fetchData: (eoaId: string, access_token: string) => getMatchingSummary(eoaId, access_token, selectedSchoolFromDataQc),
    errorMessage: 'Error occurred while fetching matching summary data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  return <WrappedComponent />;
};

export default MatchingContentWithData;