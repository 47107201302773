import { useState, useRef } from 'react';
import CustomModal from '../../reusable/CustomModal';
import selectionStepsMap from './selectionStepsConfigs';
import ReviewSelection from './ReviewSelection';
import useUploadCardData from '../../hooks/useUploadCardData';
import SelectionStepRenderer from './SelectionStepRenderer';

import './UploadPrompt.css';

interface SelectedData {
  [key: string]: string[];
}

function UploadPrompt() {
  const { fileType, handleClose, fileMetadata } = useUploadCardData();

  const [currentStepIndex, setCurrentStep] = useState(0);
  const [selectedData, setSelectedData] = useState<SelectedData>(fileMetadata);
  const cachedData = useRef<string[] | null>(null);

  const selectionSteps = selectionStepsMap[fileType].selectionSteps;
  const currentStep = selectionSteps[currentStepIndex];
  const isLastStep = currentStepIndex + 1 === selectionSteps.length;

  const handleSelection = (stepKey: string, isSelected: boolean, value: string[]) => {
    if (isSelected) {
      if (!selectedData[stepKey]) {
        selectedData[stepKey] = value;
      }
      else {
        let uniqueValues = value.filter(selectedValue => !selectedData[stepKey].includes(selectedValue));
        selectedData[stepKey] = selectedData[stepKey].concat(uniqueValues);
      }
    } else {
      selectedData[stepKey] = selectedData[stepKey].filter(selectedValue => !value.includes(selectedValue));
    }

    setSelectedData({ ...selectedData });
  };

  const handleEdit = (stepKey: string) => {
    let step = selectionSteps.findIndex(selectionStep => selectionStep.key === stepKey);

    setCurrentStep(step);
  };
  
  return (
    <CustomModal 
      titleComponent={
        <div className="upload-prompt-header">
          <p className="no-margin steps-counter">Step {currentStepIndex + 1} of {selectionSteps.length}</p>
          <p className="no-margin step-name">
            { isLastStep ?
              'Review Selections'
              :
              `Select ${currentStep.label}`
            }
          </p>
        </div>
      } 
      icon={false} 
      isOpen={true} 
      handleClose={handleClose}
      maxWidth="xs"
    > 
      { isLastStep ?
        <ReviewSelection 
          categoryName={selectionStepsMap[fileType].fileTypeName} 
          selectionData={selectedData} 
          handleEdit={handleEdit}
        />
        :
        <SelectionStepRenderer 
          handleChangeStep={() => setCurrentStep(currentStepIndex + 1)}
          handleSelection={handleSelection}
          currentStep={currentStep}
          data={cachedData.current}
          fileType={fileType}
          selectedData={selectedData[currentStep.key] || []}
          updateCachedData={(data) => cachedData.current = data}
        />
      }
    </CustomModal>
  );
}

export default UploadPrompt;