import ColoredBoxWithCheckMark from '../../reusable/ColoredBoxWithCheckMark';
import VerticalBarChart from '../../reusable/VerticalBarChart';
import './MatchingSideDrawerContent.css';
import CardContainer from '../CardContainer';
import { MatchingDetails } from '../../../interfaces/DataQcInterface';
import { getMatchingDetails } from '../../../services/dataQcDetailsService';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import { darkGreenBarColor } from '../../../theme/xq-custom-theme';
import { withData } from '../../reusable/withData';

interface MatchingSideDrawerContentProps {
  data: MatchingDetails;
}

const parseData = (data: MatchingDetails) => {

  const {demographics, students_to_test_scores, test_scores_to_students} = data;
  const {test_types, total_students_count} = students_to_test_scores;

  const chartLabels = test_types.map(({ type }) => type);
  const chartData = test_types.map(({ matched_count }) => matched_count);

  return { demographics, test_scores_to_students, total_students_count, chartData, chartLabels };
};

function MatchingSideDrawerContent({data}: MatchingSideDrawerContentProps) {

  const { demographics, test_scores_to_students, total_students_count, chartData, chartLabels } = parseData(data);

  return (
    <>
      <CardContainer title="Students matched to demographics" centerTitle={true} classes={['demographics-details-container']}> 
        <div className="colored-boxes-container">
          {demographics.map((data, index) => (
            <ColoredBoxWithCheckMark 
              key={index} 
              label={data.type} 
              not_matched_count={data.not_matched_count}
              percentage_matched={data.percentage_matched}
            />
          ))}
        </div>
      </CardContainer>
      <div className="students-scores-details-containers">
        <CardContainer title="Test scores matched to students" centerTitle={true} classes={['test-scores-to-students-details-container']}>  
          <div className="colored-boxes-container test-scores-to-students-boxes">
            {test_scores_to_students.map((data, index) => (
              <ColoredBoxWithCheckMark 
                key={index} 
                label={data.type} 
                not_matched_count={data.not_matched_count}
                percentage_matched={data.percentage_matched}
              />
            ))}
          </div>
        </CardContainer>
        <CardContainer title="Students matched to test scores" centerTitle={true} classes={['students-to-test-scores-details-container']}>
          {chartData && (
            <div>
              <VerticalBarChart 
                chartTitle={`${total_students_count} students in cohort`} 
                labels={chartLabels} 
                dataSets={[{data: chartData, backgroundColor: darkGreenBarColor}]}
                displayDataLabels={true} 
                customAspectRatio={1/0.5}
              />
            </div>
          )}
        </CardContainer>
      </div>
    </>
  );
}

const MatchingSideDrawerContentWithData = () => {
  
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const WrappedComponent = withData({
    WrappedComponent: MatchingSideDrawerContent,
    fetchData: (eoaId: string, access_token: string) => getMatchingDetails(eoaId, access_token, selectedSchoolFromDataQc),
    errorMessage: 'Error occurred while fetching matching details data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  return <WrappedComponent />;
};

export default MatchingSideDrawerContentWithData;